<template>
  <v-container class="content">
    <v-row no-gutters>
      <v-col align="center">
        <h1 class="mb-8">Users</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="10">
          <v-data-table
              :elevation="15"
              :headers="headers"
              :items="data"
              :server-items-length="totalElements"
              :options.sync="pageable"
              @update:options="fetchData"
              :footer-props="footerProps"
              class="elevation-1"
          >
            <template v-slot:item.id="{ item }">
              <user-icon :type="item.type"></user-icon> {{ item.id }}
            </template>
            <template v-slot:item.createdDate="{ item }">
              <div>
                {{ new Date(item.createdDate).toLocaleDateString() }}
              </div>
            </template>
            <template v-slot:item.name="{ item }">
              <div>
                {{ item.firstName }} {{ item.lastName }}
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-chip
                  class="mr-1"
                  color="blue accent-2"
                  dark
                  :to="{ name: 'adminUserView', params: { id: item.id } }"
              >
                View
              </v-chip>
              <v-chip
                  v-if="item.status === 'ENABLED'"
                  color="amber darken-2"
                  @click="changeStatus(item, 'DISABLED')"
              >
                Disable
              </v-chip>
              <v-chip
                  v-if="item.status === 'DISABLED' || item.status === 'CREATED'"
                  color="light-green darken-2"
                  dark
                  @click="changeStatus(item, 'ENABLED')"
              >
                Enable
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import qs from "qs";
import UserIcon from "@/components/general/UserIcon.vue";

export default {
  components: {UserIcon},
  name: "AdminUsersView",
  metaInfo: {
    title: "Users",
  },
  data() {
    return {
      data: [],
      totalElements: 0,
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
        },
        {text: "Create Date", value: "createdDate"},
        {text: "Email", value: "email"},
        {text: "Name", value: "name"},
        {text: "Company Name", value: "company.name"},
        {text: "Status", value: "status"},
        {text: "Actions", value: "actions", sortable: false},
      ],
      pageable: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
      },
      footerProps: {
        "items-per-page-options": [5, 10, 15, 25, 50, 100],
      },
    };
  },
  methods: {
    fetchData() {
      let queryParams = {
        page: this.pageable.page - 1,
        size: this.pageable.itemsPerPage,
      };
      if (this.pageable.sortBy.length) {
        let direction = this.pageable.sortDesc[0] ? ",desc" : ",asc";
        queryParams.sort = this.pageable.sortBy[0] + direction;
      }
      axios
          .get("/api/admin/user", {
            params: queryParams,
            paramsSerializer: (params) => {
              return qs.stringify(params, {arrayFormat: "repeat"});
            },
          })
          .then((response) => {
            this.data = response.data.content;
            this.totalElements = response.data.totalElements;
          });
    },
    changeStatus(user, status) {
      user.status = status

      axios
          .put("/api/admin/user/" + user.id, user)
          .then(() => {
            this.$store.dispatch('banner/open', {text: 'User ' + user.email + ' Updated!'})
          })
          .finally(() => {
            this.fetchData()
          });
    },
  },
};
</script>

<style scoped>
</style>
