var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"content"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"align":"center"}},[_c('h1',{staticClass:"mb-8"},[_vm._v("Users")])])],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"10"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"elevation":15,"headers":_vm.headers,"items":_vm.data,"server-items-length":_vm.totalElements,"options":_vm.pageable,"footer-props":_vm.footerProps},on:{"update:options":[function($event){_vm.pageable=$event},_vm.fetchData]},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('user-icon',{attrs:{"type":item.type}}),_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(new Date(item.createdDate).toLocaleDateString())+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-1",attrs:{"color":"blue accent-2","dark":"","to":{ name: 'adminUserView', params: { id: item.id } }}},[_vm._v(" View ")]),(item.status === 'ENABLED')?_c('v-chip',{attrs:{"color":"amber darken-2"},on:{"click":function($event){return _vm.changeStatus(item, 'DISABLED')}}},[_vm._v(" Disable ")]):_vm._e(),(item.status === 'DISABLED' || item.status === 'CREATED')?_c('v-chip',{attrs:{"color":"light-green darken-2","dark":""},on:{"click":function($event){return _vm.changeStatus(item, 'ENABLED')}}},[_vm._v(" Enable ")]):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }